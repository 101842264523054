const dev = {
    analytics: {
        REGION: "us-east-1",
        APP_ID: "b22d82bd49b34e088c4e3ffde1b25b4e",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_YH3I7upOU",
        APP_CLIENT_ID: "ut6u4sjuc989brkh3gqld3eru",
        IDENTITY_POOL_ID: "us-east-1:9116e598-2273-4699-b833-cca4b14211d0"
    },
    s3: {
        REGION: "us-east-1",
        BUCKET: "hoopsai-prod-datasets"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://go65alad96.execute-api.us-east-1.amazonaws.com/dev"
    },
    appsync: {
        REGION: "us-east-1",
        URL: "https://olledskw3fdgbjd7uhdt67jbde.appsync-api.us-east-1.amazonaws.com/graphql",
        AUTH_TYPE: "AWS_IAM",
        AUTH_KEY: "null"
    }
};

const prod = {
    analytics: {
        REGION: "us-east-1",
        APP_ID: "b22d82bd49b34e088c4e3ffde1b25b4e",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_YH3I7upOU",
        APP_CLIENT_ID: "ut6u4sjuc989brkh3gqld3eru",
        IDENTITY_POOL_ID: "us-east-1:9116e598-2273-4699-b833-cca4b14211d0"
    },
    s3: {
        REGION: "us-east-1",
        BUCKET: "hoopsai-prod-datasets"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://go65alad96.execute-api.us-east-1.amazonaws.com/dev"
    },
    appsync: {
        REGION: "us-east-1",
        URL: "https://olledskw3fdgbjd7uhdt67jbde.appsync-api.us-east-1.amazonaws.com/graphql",
        AUTH_TYPE: "AWS_IAM",
        AUTH_KEY: "null"
    }
};

// Default to dev if not set
const awsConfig = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...awsConfig
};
