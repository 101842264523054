import React, {Component} from 'react';
import icon1 from '../resources/imgs/installPopup1.png';
import icon2 from '../resources/imgs/installPopup2.png';

import {withStyles} from "@material-ui/core";


const styles = {
    installPopup: {
        position: 'absolute',
        right: '0',
        bottom: '15px',
        left: '0',
        display: 'flex',
        marginBottom: '16px',
        padding: '5px 10px',
        paddingBottom: '10px',
        fontSize: '12px',
        borderRadius: '5px',
        color: '#303030',
        // position: 'relative',
        background: '#f2f8ff',
        alignItems: 'center',
        '&:before': {
            position: 'absolute',
            top: '100%',
            left: '50%',
            width: '0',
            height: '0',
            border: 'solid transparent',
            content: '',
            pointerEvents: 'none',
            marginLeft: '-16px',
            borderWidth: '16px',
            borderColor: 'rgba(194, 225, 245, 0)',
            borderTopColor: '#f9f6f1'
        },
        '&:after': {
            position: 'absolute',
            top: '100%',
            left: '50%',
            width: '0',
            height: '0',
            border: 'solid transparent',
            content: '',
            pointerEvents: 'none',
            marginLeft: '-15px',
            borderWidth: '15px',
            borderColor: 'rgba(136, 183, 213, 0)',
            borderTopColor: '#f2f8ff'
        }
    },
    icon1: {
        width: '25px',
        verticalAlign: 'middle'
    },
    icon2: {
        height: '25px',
        verticalAlign: 'middle',
    }
};

class InstallPopup extends Component {
    state = {
        showInstallMessage: false,
    };

    componentWillMount() {
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };

        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        if (isIos() && !isInStandaloneMode()) {
            this.setState({showInstallMessage: true});
        }
    }

    render() {
        const {classes} = this.props;
        const {showInstallMessage} = this.state;
        if (!showInstallMessage) return null;

        return (
            <div className={classes.installPopup}>
                <div className={classes.left}>
                    Install this webapp on your iPhone: tap
                    <img src={icon1} className={classes.icon1} alt=""/>
                    and then Add to homescreen.
                    <img src={icon2} className={classes.icon2} alt=""/>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(InstallPopup);
