import React, {Component} from 'react';

import Routes from "./Routes";
import MenuAppBar from './containers/AppBar';
import InstallPopup from './components/InstallPopup';
import {Authenticator} from 'aws-amplify-react';
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import Amplify from "aws-amplify";

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {hoopsTheme} from './consts/appParams'
import './App.css';

const theme = createMuiTheme(hoopsTheme);

class App extends Component {
    state = {
        authState: this.props.authState || null,
        authData: this.props.authData || null,
    };

    handleAuthStateChange(state, data) {
        if (state === 'signedIn') {
            Analytics.record('userSignin', {username: data.username});
            Amplify.configure({aws_appsync_apiKey: () => Auth.currentCredentials()});
        }

        this.setState({authState: state, authData: data});
    }

    render() {
        const {authState} = this.state;
        const signedIn = (authState === 'signedIn');

        if (signedIn) {
            return (
                <MuiThemeProvider theme={theme}>
                    <div className="App">
                        <MenuAppBar/>
                        <div className="App_content">
                            <Routes/>
                        </div>
                        <InstallPopup/>
                    </div>
                </MuiThemeProvider>
            )
        }

        return <Authenticator
            onStateChange={this.handleAuthStateChange.bind(this)}
        />
    }
}

export default App;
