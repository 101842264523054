import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';
import App from './App';
import awsConfig from './aws-config';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import(/* webpackPrefetch: true */  './index.css');

Amplify.configure({
    Analytics: {
        AWSPinpoint: {
            appId: awsConfig.analytics.APP_ID,
            region: awsConfig.analytics.REGION
        }
    },
    Auth: {
        mandatorySignIn: true,
        region: awsConfig.cognito.REGION,
        userPoolId: awsConfig.cognito.USER_POOL_ID,
        identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID
    },
    Storage: {
        region: awsConfig.s3.REGION,
        bucket: awsConfig.s3.BUCKET,
        identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "predict",
                endpoint: awsConfig.apiGateway.URL,
                region: awsConfig.apiGateway.REGION
            },
            {
                name: "oldPredict-API",
                endpoint: "https://7ot557bovc.execute-api.us-east-1.amazonaws.com/default",
                region: awsConfig.apiGateway.REGION
            }
        ],

        aws_appsync_graphqlEndpoint: awsConfig.appsync.URL,
        aws_appsync_region: awsConfig.appsync.REGION,
        aws_appsync_authenticationType: awsConfig.appsync.AUTH_TYPE,
        aws_appsync_apiKey: awsConfig.appsync.AUTH_KEY,
    },
    aws_appsync_graphqlEndpoint: awsConfig.appsync.URL,
    aws_appsync_region: awsConfig.appsync.REGION,
    aws_appsync_authenticationType: awsConfig.appsync.AUTH_TYPE,
    aws_appsync_apiKey: awsConfig.appsync.AUTH_KEY,
});

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// const federated = {
//     facebook_app_id: '239562436624010'
// };

ReactDOM.render(
    <Router>
        <App/>
    </Router>,
    document.getElementById('root'));

serviceWorker.register();
