import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import AppliedRoute from "./components/AppliedRoute";

const AsyncHomeContainer = asyncComponent(() => import("./containers/HomeContainer"));
const AsyncGame = asyncComponent(() => import("./containers/Game"));
const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));
const AsyncSearch = asyncComponent(() => import("./containers/Search"));
const AsyncSearchResults = asyncComponent(() => import("./containers/SearchResults"));
const AsyncPlayer = asyncComponent(() => import("./components/Player"));

const AsyncSettings = asyncComponent(() => import("./containers/drawerContainers/Settings"));
const AsyncAbout = asyncComponent(() => import("./containers/drawerContainers/About"));
const AsyncFeedback = asyncComponent(() => import("./containers/drawerContainers/Feedback"));
const AsyncHelp = asyncComponent(() => import("./containers/drawerContainers/Help"));
const AsyncWhatsNext = asyncComponent(() => import("./containers/drawerContainers/WhatsNext"));


export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={AsyncHomeContainer} props={childProps}/>
        <AppliedRoute path="/index.html" exact component={AsyncHomeContainer} props={childProps}/>

        <AppliedRoute path="/search" exact component={AsyncSearch} props={childProps}/>
        <AppliedRoute path="/search-results" exact component={AsyncSearchResults} props={childProps}/>

        <AppliedRoute path="/game/:path" exact component={AsyncGame} props={childProps}/>

        <AppliedRoute path="/player/:path" exact component={AsyncPlayer} props={childProps}/>

        <AppliedRoute path="/settings" exact component={AsyncSettings} props={childProps}/>
        <AppliedRoute path="/about" exact component={AsyncAbout} props={childProps}/>
        <AppliedRoute path="/feedback" exact component={AsyncFeedback} props={childProps}/>
        <AppliedRoute path="/help" exact component={AsyncHelp} props={childProps}/>
        <AppliedRoute path="/whats-next" exact component={AsyncWhatsNext} props={childProps}/>

        {/* Finally, catch all unmatched routes */}
        <Route component={AsyncNotFound}/>
    </Switch>;