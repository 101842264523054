import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import MenuIcon from '../resources/icons/menu_icon.svg';
import HoopsLogo from '../resources/hoops_logo.svg';
import HoopsIcon from '../resources/hoops_icon.svg';
import Drawer from './Drawer'

import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        flexGrow: 1,
        position: 'fixed',
        width: '100%',
        zIndex: 2,
        '@media (min-width: 800px)': {
            width: '800px'
        }
    },
    grow: {
        flexGrow: 1,
        textAlign: 'center'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    appBarRoot: {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: 'none',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 'var(--app-bar-height)',
        textAlign: 'center'
    },
    toolbarRoot: {
        justifyContent: 'space-between'
    },
    live: {
        width: '48px',
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to right, #FB758A, #F18A83)',
        textAlign: 'center',
        lineHeight: '2',
        color: '#fff'
    }
});

class MenuAppBar extends Component {
    constructor(props) {
        super(props);

        this.homeRoute = ['/', '/index.html'];

        this.state = {
            searchOpen: false,
            drawerOpen: false,
            backRoute: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.handleRouteChange();
        }
    }

    componentDidMount() {
        this.handleRouteChange();
    }

    handleRouteChange() {
        const isBackRoute = !this.homeRoute.includes(this.props.location.pathname);
        this.setState({backRoute: isBackRoute})
    }

    handleSearchClick() {
        this.setState({searchOpen: true});
        this.props.history.push("/search");
    }

    handleBackClick() {
        if (this.props.history.location.pathname !== '/search-results') {
            this.setState({searchOpen: false});
        }

        this.props.history.goBack();
    }

    handleDrawerOpen() {
        this.setState({drawerOpen: true})
    }

    handleDrawerClose() {
        this.setState({drawerOpen: false})
    }

    renderBackBar(classes) {
        let [awayTeam, homeTeam] = this.props.location.pathname.split('@');
        awayTeam = awayTeam.split('_')[1];
        return (
            <Fragment>
                <IconButton aria-haspopup="true" color="inherit" onClick={this.handleBackClick.bind(this)}>
                    <KeyboardBackspace style={{color: '#25313B'}}/>
                </IconButton>
                {awayTeam &&
                <Fragment>
                    <div className={classes.title}>
                        <img src={HoopsIcon} alt="hoopsIcon" height="24"/>
                        <Typography variant="body1" style={{color: '#25313B'}}>
                            {awayTeam} vs {homeTeam}
                        </Typography>
                    </div>
                    <Typography variant="caption" className={classes.live}>

                    </Typography>
                </Fragment>
                }
            </Fragment>
        )
    }

    renderAppBar(classes) {
        return (
            <Fragment>
                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                            onClick={this.handleDrawerOpen.bind(this)}>
                    <img src={MenuIcon} alt="menuIcon"/>
                </IconButton>
                <img src={HoopsLogo} alt="hoopsLogo" height="24"/>
                <div>
                    <IconButton aria-haspopup="true" color="inherit" onClick={this.handleSearchClick.bind(this)}>
                        <Search style={{color: '#304A60'}}/>
                    </IconButton>
                </div>
            </Fragment>
        )
    }

    render() {
        const {classes} = this.props;

        const barFragment = (this.state.searchOpen || this.state.backRoute) ? this.renderBackBar(classes) : this.renderAppBar(classes);

        return (
            <div className={classes.root}>
                <Drawer onDrawerClose={this.handleDrawerClose.bind(this)} open={this.state.drawerOpen}/>
                <AppBar position="static" classes={{
                    root: classes.appBarRoot,
                }}>
                    <Toolbar classes={{
                        root: classes.toolbarRoot,
                    }}>
                        {barFragment}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(withRouter(MenuAppBar));
