export const hoopsTheme = {
    typography: {
        useNextVariants: true,
        fontSize: 12,
        fontFamily: [
            'Nunito Sans',
            '"Helvetica Neue"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

        body2: {
            fontWeight: 600,
            fontSize: '0.875rem', //14px at 12base
            color: '#232A3D',
        },
        body1: {
            fontSize: '0.813rem', //13px at 12base
            fontWeight: 700
        },
        caption: {
            color: '#66788D',
            fontWeight: 400,
            fontSize: '0.813rem', //13px at 12base
            lineHeight: '1.5rem'
        },
        h6: {
            fontWeight: 600,
            fontSize: '0.75rem', //14px at 12base
            color: '#25313B',
        },
        h5: {
            color: '#25313B',
            fontSize: "0.938rem", //15px at 12base
            fontWeight: 600
        },
        h4: {
            color: '#66788D',
            fontSize: "0.938rem", //15px at 12base
            fontWeight: 600
        },
        h2: {
            fontWeight: 700,
            fontSize: "1.667rem", //20px at 12base
            color: '#25313B'
        },
        h3: {
            fontWeight: 700,
            fontSize: "1.875rem", //30px at 12base
            color: '#25313B'
        },
        h1: {
            color: '#25313B',
            fontSize: "1.5rem", //24px at 12base
            fontWeight: 800
        }
    },
    spacing: {
        unit: 10
    },
    shape: {
        borderRadius: 12
    },
    palette: {
        primary: {
            light: '#F36475',
            main: '#F36475',
            dark: '#FD854F',
            contrastText: '#66788D',
            hoopsGreen: '#6AC89E'
        },
        secondary: {
            light: '#FD854F',
            main: '#FD854F',
            dark: '#FD854F',
            contrast: '#ffaa64',
            contrastText: '#FD854F',
        },
        background: {
            hoopsBackground: '#F4F5F7',
            hoopsGrey: '#E4E9EF'
        }
    },
    shadows: [
        "none",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)",
        "0px 10px 40px  rgba(95, 131, 165, 0.0968)"
    ],
    overrides: {
        MuiCardContent: {
            root: {
                paddingBottom: 20,
                paddingRight: 20,
                paddingLeft: 20,
                paddingTop: 20,
                '&:last-child': {
                    paddingBottom: 20,
                },
            }
        },
        MuiButtonBase: {
            root: {}
        },
        MuiButton: {
            sizeLarge: {
                padding: 'auto',
                width: '100%',
                borderRadius: '50px',
            }
        },
        MuiTab: {
            root: {
                borderRadius: '50px',
                fontWeight: 600,
                fontSize: '0.813rem', //13px at 12base
            },
            selected: {
                backgroundColor: 'white',
                boxShadow: '0px 5px 15px  rgba(211, 219, 226, 0.506)',
                fontWeight: 600,
                fontSize: '0.813rem', //13px at 12base
            }
        }
    }
};

export const gameOverPercentage = 5;

export const gameOver = {
    "NO_WINNER": 0,
    "HOME_WON": 1,
    "AWAY_WON": 2
};

Object.freeze(gameOver);

export const awsConfig = {
    videoBucket: "https://s3.amazonaws.com/hoopsaife-userfiles-mobilehub-929946515/pbp_videos/",
    publicAssets: "https://s3.amazonaws.com/hoops-public-assets/"
};

export const appConfig = {
    adminOnlyMode: true
};

export const playLengthInPixels = 15;

export const commentaryTypeDict = {
    key_play: {name: 'Key Play', graphicsSize: 125, graphicsType: 'winProb'},
    player_notable_stat: {name: 'Player Stat', graphicsSize: 0, graphicsType: null},
    team_notable_stat: {name: 'Team Stat', graphicsSize: 0, graphicsType: null},
    pre_game_update: {name: 'Pre-Game Update', graphicsSize: 0, graphicsType: null},
    basic_description: {name: 'Basic Description', graphicsSize: 0, graphicsType: null},
    start_of_period: {name: 'Start of Period', graphicsSize: 0, graphicsType: null},
    game_end: {name: 'End of Game', graphicsSize: 0, graphicsType: null},
    recap: {name: 'In-progress Recap', graphicsSize: 0, graphicsType: null},
};
