import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import HoopsLogo from '../resources/hoops_logo.svg';

import WhatsNextIcon from '../resources/icons/whats_Next.png';
import AboutIcon from '../resources/icons/about_icon.png';
import FeedbackIcon from '../resources/icons/feedback_icon.png';
import HelpIcon from '../resources/icons/help_icon.png';
import SettingsIcon from '../resources/icons/settings_icon.png';
import {Link} from "react-router-dom";

const styles = {
    list: {
        width: 250,
    },
    listHeader: {
        margin: 'var(--default-margin)'
    },
    closeIcon: {
        marginRight: 'var(--default-margin)'
    }
};

const DrawerLink = props => {
    return <Link to={{pathname: `/${props.draweritem}`}} {...props}/>;
};

const drawerListItems = (
    <div>
        <ListItem button component={DrawerLink} draweritem='settings'>
            <ListItemIcon>
                <img src={SettingsIcon} alt="SettingsIcon"/>
            </ListItemIcon>
            <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button component={DrawerLink} draweritem='about'>
            <ListItemIcon>
                <img src={AboutIcon} alt="AboutIcon"/>
            </ListItemIcon>
            <ListItemText primary="About HoopsAI" />
        </ListItem>
        <ListItem button component={DrawerLink} draweritem='feedback'>
            <ListItemIcon>
                <img src={FeedbackIcon} alt="FeedbackIcon"/>
            </ListItemIcon>
            <ListItemText primary="Feedback" />
        </ListItem>
        <ListItem button component={DrawerLink} draweritem='help'>
            <ListItemIcon>
                <img src={HelpIcon} alt="HelpIcon"/>
            </ListItemIcon>
            <ListItemText primary="Help" />
        </ListItem>
        <ListItem button component={DrawerLink} draweritem='whats-next'>
            <ListItemIcon>
                <img src={WhatsNextIcon} alt="WhatsNextIcon"/>
            </ListItemIcon>
            <ListItemText primary="What's Next" />
        </ListItem>
    </div>
);

class TemporaryDrawer extends Component {
    closeDrawer = () => () => {
        this.props.onDrawerClose()
    };

    render() {
        const { classes } = this.props;

        const sideList = (
            <div className={classes.list}>
                <List>{drawerListItems}</List>
            </div>
        );

        const header = (
            <div className={classes.listHeader}>
                <CloseIcon className={classes.closeIcon} style={{color: "#232A3D"}}/>
                <img src={HoopsLogo} alt="hoopsLogo" height="24"/>
            </div>
        );

        return (
            <div>
                <Drawer open={this.props.open} onClose={this.closeDrawer()}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.closeDrawer()}
                        onKeyDown={this.closeDrawer()}
                    >
                        {header}
                        {sideList}
                    </div>
                </Drawer>
            </div>
        );
    }
}

TemporaryDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(TemporaryDrawer);